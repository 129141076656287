import { css } from 'styled-components';
import { responsive, StyledTheme } from '../../../../styles';

export const TextInputNewsletterStyles = css<{ theme: StyledTheme }>`
  position: relative;
  width: 100%;

  > input {
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out 0s;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};
    color: ${({ theme }) => theme.palette.primaryBackground.color};
    padding: 1.05rem 0 1.1rem 1rem;
    margin: 0;
    height: 3.55rem;
    background-color: ${({ theme }) => theme.palette.primary.color};
    border: 1px solid
      ${({ theme }) => theme.palette.primaryBackground.rgba('1')};
    border-right: none;
    border-radius: 0;
    outline: none;
    font-size: 1.045rem;

    &::placeholder {
      color: ${({ theme }) => theme.palette.primaryBackground.color};
      opacity: 50%;
    }

    &:hover,
    &:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      color: ${({ theme }) => theme.palette.primaryBackground.color} !important;
      background-color: ${({ theme }) =>
        theme.palette.primary.color} !important;
      border: 1px solid
        ${({ theme }) => theme.palette.primaryBackground.rgba('1')};
      border-right: none;
      -webkit-text-fill-color: ${({ theme }) =>
        theme.palette.primaryBackground.color};
      -webkit-box-shadow: 0 0 0px 1000px
        ${({ theme }) => theme.palette.primary.color} inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    ${responsive.desktop.small(
      css`
        padding-top: 1.1rem;
        padding-bottom: 1.04rem;
      `
    )}
  }

  .error-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
    color: ${({ theme }) => theme.palette.invalidBackground.color};
  }
`;
