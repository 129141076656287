import styled from 'styled-components';
import { StyledTheme } from '../../../../styles';
import { DropdownBaseStyles } from './dropdown-base.styles';
import { DropdownFormStyles } from './dropdown-form.styles';
import { DropdownSelectStyles } from './dropdown-select.styles';

export const DropdownStyled = styled.div<{ theme: StyledTheme }>`
  &.dropdownBase {
    ${DropdownBaseStyles}
  }

  &.dropdownForm {
    ${DropdownFormStyles}
  }

  &.dropdownSelect {
    ${DropdownSelectStyles}
  }
`;
