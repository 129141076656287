import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { HeaderAccountProps } from '../interfaces';

export const HeaderMobileAccountFragment = (props: {
  account: HeaderAccountProps;
}) => {
  const {
    account: {
      isAccount,
      accountLinks: {
        dashboard,
        plan,
        paymentDetails,
        personalDetails,
        shippingAddress,
        billingAddress,
        orderHistory,
        logout,
      },
    },
  } = props;

  return (
    <AnimatePresence>
      {props.account.accountOpen && (
        <div className="account-overlay">
          <motion.div
            key="mobile-menu"
            animate={{ x: 0 }}
            initial={{ x: 500 }}
            exit={{ x: 500 }}
            transition={{
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.3,
            }}
          >
            <div className="account-sidebar account-left">
              <div className="account-header">
                <div
                  className="account-header-close"
                  onClick={() => props.account.closeAccountSiderbarHandler()}
                >
                  <img
                    src="/images/icons/blue-close-icon.svg"
                    alt="Close account"
                  />
                </div>
              </div>
              <div className="account-body">
                <div className="account-links">
                  <div
                    className="account-link"
                    onClick={() => dashboard.onLinkHandler()}
                  >
                    {dashboard.label}
                  </div>

                  {isAccount && (
                    <div
                      className="account-link"
                      onClick={() => plan.onLinkHandler()}
                    >
                      {plan.label}
                    </div>
                  )}

                  {isAccount && (
                    <div
                      className="account-link"
                      onClick={() => paymentDetails.onLinkHandler()}
                    >
                      {paymentDetails.label}
                    </div>
                  )}

                  {isAccount && (
                    <div
                      className="account-link"
                      onClick={() => personalDetails.onLinkHandler()}
                    >
                      {personalDetails.label}
                    </div>
                  )}

                  {isAccount && (
                    <div
                      className="account-link"
                      onClick={() => shippingAddress.onLinkHandler()}
                    >
                      {shippingAddress.label}
                    </div>
                  )}

                  {isAccount && (
                    <div
                      className="account-link"
                      onClick={() => billingAddress.onLinkHandler()}
                    >
                      {billingAddress.label}
                    </div>
                  )}

                  {isAccount && (
                    <div
                      className="account-link"
                      onClick={() => orderHistory.onLinkHandler()}
                    >
                      {orderHistory.label}
                    </div>
                  )}

                  <div
                    className="account-link"
                    onClick={() => logout.onLinkHandler()}
                  >
                    {logout.label}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
