import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const ButtonInputPromoStyles = css<{ theme: StyledTheme }>`
  > button {
    cursor: pointer;
    width: 100%;
    height: 3rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    background-color: unset;
    text-transform: uppercase;
    border: 1px solid ${({ theme }) => theme.palette.primary.rgba('1')};
    color: ${({ theme }) => theme.palette.primary.color};
    transition: ease-in 0.2s;
    font-family: 'Lola';
    font-weight: 600;

    &:hover:enabled {
      color: ${({ theme }) => theme.palette.primaryBackground.color};
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.primary.color};
      background-color: ${({ theme }) => theme.palette.primary.color};
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
      border-width: 1px;
      border-style: solid;
      text-align: center;
      color: ${({ theme }) => theme.palette.primary.color};
    }
  }
`;
