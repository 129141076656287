import styled from 'styled-components';
import { StyledTheme } from '../../../../styles';
import { CheckoutUpsellBaseStyles } from './checkout-upsell-base.styles';
import { CheckoutUpsellBasketStyles } from './checkout-upsell-basket.styles';

export const CheckoutUpsellStyled = styled.div<{ theme: StyledTheme }>`
  &.checkoutUpsellBase {
    ${CheckoutUpsellBaseStyles}
  }

  &.checkoutUpsellBasket {
    ${CheckoutUpsellBasketStyles}
  }
`;
