import React, { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { ExtendedRouteItem, RouteItem } from '../../../../hooks';
import { HeaderDesktopShopAllFragment } from './header-desktop-shop-all.fragment';
import { HeaderDesktopBasketFragment } from './header-desktop-basket.fragment';
import { HeaderDesktopAccountFragment } from './header-desktop-account.fragment';
import {
  HeaderAccountProps,
  HeaderBannerProps,
  HeaderBasketProps,
  HeaderProductProps,
} from '../interfaces';
import {
  ButtonInputProps,
  DropdownProps,
  DropdownElement,
  ButtonInputElement,
} from '../../../elements';

export const HeaderDesktopFragment = (props: {
  customer?: Customer;
  account: HeaderAccountProps;
  shopAll: HeaderProductProps;
  basket: HeaderBasketProps;
  getStartedProps?: ButtonInputProps;
  homeLink: ExtendedRouteItem;
  loginLink: RouteItem;
  logoutLink: RouteItem;
  visionCenterLink?: RouteItem;
  doctorsDropdown?: DropdownProps;
  aboutDropdown: DropdownProps;
  regionDropdown: DropdownProps;
  banner?: HeaderBannerProps;
}) => {
  const preventShopAll = useRef(false);
  const preventBasket = useRef(false);
  const preventAccount = useRef(false);

  return (
    <nav className="header-desktop">
      {props.banner && (
        <div className="header-banner">
          <span
            onClick={() =>
              props.banner
                ? props.banner.onClickHandler?.onLinkHandler()
                : undefined
            }
          >
            {props.banner.line1}
            {props.banner?.line2 && (
              <>
                <br />
                {props.banner.line2}
              </>
            )}
          </span>
        </div>
      )}
      <div className="header-navbar">
        <div className="left-side-links">
          <div className="shop-all-link">
            <span
              className="header-link"
              onMouseUp={() => {
                if (!preventShopAll.current) {
                  props.shopAll!.openShopAllHandler();
                }
              }}
              onMouseDown={() => {
                if (props.shopAll?.isOpen) {
                  preventShopAll.current = true;
                } else {
                  preventShopAll.current = false;
                  props.shopAll!.openShopAllHandler();
                }
              }}
            >
              {props.shopAll.label}
              {props.shopAll.isOpen ? (
                <img src="/images/icons/arrow-up.svg" />
              ) : (
                <img src="/images/icons/toggle-down.svg" />
              )}
            </span>
          </div>

          {props.visionCenterLink && (
            <div className="vision-center-link">
              <span
                className="header-link"
                onClick={() =>
                  props.visionCenterLink
                    ? props.visionCenterLink.onLinkHandler()
                    : undefined
                }
              >
                {props.visionCenterLink.label}
              </span>
            </div>
          )}

          {props.doctorsDropdown && (
            <div className="doctors-links">
              <DropdownElement {...props.doctorsDropdown} />
            </div>
          )}
        </div>

        <div className="logo">
          {' '}
          <img
            src={props.homeLink.image}
            alt={props.homeLink.alt}
            width={170}
            onClick={() => props.homeLink.onLinkHandler()}
          />
        </div>

        <div className="right-side-links">
          <div className="get-started-link">
            {!props.account.isSubscriber && props.getStartedProps && (
              <ButtonInputElement {...props.getStartedProps} />
            )}
          </div>

          <div className="about-links">
            <DropdownElement {...props.aboutDropdown} />
          </div>

          <div className="login-link">
            {!props.account.isAuthenticated || !props.customer ? (
              <span
                className="header-link"
                onClick={() => props.loginLink.onLinkHandler()}
              >
                {props.loginLink.label}
              </span>
            ) : (
              <span
                className="header-link"
                onClick={() => props.logoutLink.onLinkHandler()}
              >
                {props.logoutLink.label}
              </span>
            )}
          </div>

          {(props.basket?.cartItems || 0) > 0 && (
            <div
              className="cart-link"
              onMouseUp={() => {
                if (!preventBasket.current) {
                  props.basket!.basketDisplay.showBasket();
                }
              }}
              onMouseDown={() => {
                if (props.basket?.basketDisplay.basketOpen) {
                  preventBasket.current = true;
                } else {
                  preventBasket.current = false;
                  props.basket!.basketDisplay.showBasket();
                }
              }}
            >
              <div className="cart-item-count">
                {props.basket?.cartItems || 0}
              </div>
              <img src="/images/icons/cart-icon.svg" />
            </div>
          )}

          {props.account.isAuthenticated ? (
            <div
              className="account-link"
              onMouseUp={() => {
                if (!preventAccount.current) {
                  props.account!.openAccountSiderbarHandler();
                }
              }}
              onMouseDown={() => {
                if (props.account?.accountOpen) {
                  preventAccount.current = true;
                } else {
                  preventAccount.current = false;
                  props.account!.openAccountSiderbarHandler();
                }
              }}
            >
              <div>W</div>
            </div>
          ) : (
            <div className="region-links">
              <DropdownElement {...props.regionDropdown} />
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {props.shopAll.isOpen && (
          <motion.div
            key="shop-all"
            style={{
              overflow: 'hidden',
              position: 'absolute',
              width: '100%',
            }}
            animate={{ height: '19.5rem' }}
            initial={{ height: '0rem' }}
            exit={{ height: '0rem' }}
            transition={{
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.2,
            }}
          >
            <HeaderDesktopShopAllFragment shopAll={props.shopAll} />
          </motion.div>
        )}
      </AnimatePresence>

      <HeaderDesktopBasketFragment basket={props.basket} />

      <HeaderDesktopAccountFragment account={props.account} />
    </nav>
  );
};
