import React, { useRef } from 'react';
import { HeaderProductProps } from '../interfaces';
import useOnClickOutside from 'use-onclickoutside';

export const HeaderDesktopShopAllFragment = (props: {
  shopAll: HeaderProductProps;
}) => {
  const { shopAll } = props;

  const {
    region,
    productLinksExtended: {
      vitaminBoostLenses,
      hydraBoostLenses,
      starterkit,
      eyeDrops,
      blueLightGlasses,
    },
  } = shopAll;

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (shopAll?.isOpen) {
      shopAll.closeShopAllHandler();
    }
  });

  return (
    <div className="shop-all-container" ref={ref}>
      <div
        className={`shop-all-menu ${
          region === 'US' ? 'shop-all-us' : 'shop-all-row'
        }`}
      >
        {region === 'US' ? (
          <div
            className="shop-all-link vitamin-boost-lenses"
            onClick={() => vitaminBoostLenses.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img
                src={vitaminBoostLenses.image}
                alt={vitaminBoostLenses.alt}
              />
            </div>
            <span className="product-header">{vitaminBoostLenses.label}</span>
            <span className="product-info">{vitaminBoostLenses.info}</span>
          </div>
        ) : (
          <div
            className="shop-all-link hydra-boost-lenses"
            onClick={() => hydraBoostLenses.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img src={hydraBoostLenses.image} alt={hydraBoostLenses.alt} />
            </div>
            <span className="product-header">{hydraBoostLenses.label}</span>
            <span className="product-info">{hydraBoostLenses.info}</span>
          </div>
        )}
        {region !== 'US' ? (
          <div
            className="shop-all-link vitamin-boost-lenses"
            onClick={() => vitaminBoostLenses.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img
                src={vitaminBoostLenses.image}
                alt={vitaminBoostLenses.alt}
              />
            </div>
            <span className="product-header">{vitaminBoostLenses.label}</span>
            <span className="product-info">{vitaminBoostLenses.info}</span>
          </div>
        ) : (
          <div
            className="shop-all-link hydra-boost-lenses"
            onClick={() => hydraBoostLenses.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img src={hydraBoostLenses.image} alt={hydraBoostLenses.alt} />
            </div>
            <span className="product-header">{hydraBoostLenses.label}</span>
            <span className="product-info">{hydraBoostLenses.info}</span>
          </div>
        )}
        {starterkit?.onLinkHandler && (
          <div
            className="shop-all-link starter-kit"
            onClick={() => starterkit.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img src={starterkit.image} alt={starterkit.alt} />
            </div>
            <span className="product-header">{starterkit.label}</span>
            <span className="product-info">
              {starterkit.info?.split('+')[0]}
              <br />+{starterkit.info?.split('+')[1]}
            </span>
          </div>
        )}
        {eyeDrops?.onLinkHandler && (
          <div
            className="shop-all-link hydration-drops"
            onClick={() => eyeDrops.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img src={eyeDrops.image} alt={eyeDrops.alt} />
            </div>
            <span className="product-header">{eyeDrops.label}</span>
            <span className="product-info">{eyeDrops.info}</span>
          </div>
        )}
        {blueLightGlasses?.onLinkHandler && (
          <div
            className="shop-all-link blue-light-glasses"
            onClick={() => blueLightGlasses.onLinkHandler()}
          >
            <div className="shop-all-link-image">
              <img src={blueLightGlasses.image} alt={blueLightGlasses.alt} />
            </div>
            <span className="product-header">{blueLightGlasses.label}</span>
            <span className="product-info">{blueLightGlasses.info}</span>
          </div>
        )}
      </div>
    </div>
  );
};
