import { useState } from 'react';
import { OnBlurProps } from '../interfaces';

export function useOnBlurEffect(
  props: OnBlurProps
): [boolean, (blurred: boolean) => void, (e: any) => void] {
  const [isBlurred, setIsBlurred] = useState(true);

  const onBlur = (e: any) => {
    if (typeof e.preventDefault !== 'undefined') {
      e.preventDefault();
    }

    if (typeof props.onBlurHandler !== 'undefined') {
      props.onBlurHandler({ detail: { id: props.id, name: props.name } });
    }

    if (props.validateOnBlur && props.validate) {
      if (props.files) {
        const {
          lastModified,
          lastModifiedDate,
          name,
          size,
          type,
          webkitRelativePath,
        } = e.target.files[0];

        props.validate(e.target.value, {
          lastModified,
          lastModifiedDate,
          name,
          size,
          type,
          webkitRelativePath,
        });
      } else {
        props.validate(e.target.value);
      }
    }

    setIsBlurred(true);
  };

  return [isBlurred, setIsBlurred, onBlur];
}
