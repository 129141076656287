import { StyledTheme } from '../interfaces';
import { css } from 'styled-components';
import { responsive } from '../utils';

export const StyledThemeCheckoutMixin = css<{ theme: StyledTheme }>`
  .grid-display {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  .checkout-segment {
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.color};

    > div {
      ${responsive.mobile.large(css`
        margin-left: 0.75rem;
        margin-right: 0.75rem;
      `)}
    }

    ${responsive.desktop.small(css`
      margin-right: 3rem;
    `)}

    .button-container {
      margin: 2rem 0.25rem 1rem;

      ${responsive.desktop.small(css`
        margin-bottom: 2rem;
      `)}

      button {
        width: 100%;

        ${responsive.desktop.small(css`
          width: 260px;
        `)}
      }
    }
  }

  .checkout-segment:last-child {
    border-bottom: none;
  }

  .checkout-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    max-width: 1280px;
    width: 100%;
    margin: 3.75rem auto 0rem auto;

    ${responsive.desktop.small(css`
      margin: 4rem auto 0rem auto;
      padding-bottom: 1rem;
    `)}

    > div {
      flex: 1;
      min-width: 0;
      box-sizing: border-box;
    }

    > div:first-child {
      flex-basis: 60%;
    }

    > div:last-child {
      flex-basis: 40%;
    }
  }

  .checkout-info-container {
    background-color: rgb(229, 234, 245);
    padding: 1.1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
  }

  .checkout-title-content {
    color: ${({ theme }) => theme.palette.waldoPrimary};

    > h2 {
      margin: 1.25rem 0 0;
      font-family: ${({ theme }) => theme.typography.contentFontFamily};
      font-size: 1.25rem;
      font-weight: 400;
    }
    > p {
      margin: 0;
      font-weight: 200;

      > a {
        color: ${({ theme }) => theme.palette.waldoPrimary};
        font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
      }
    }

    &.checkout-segment-closed {
      margin-bottom: 0;

      > h2 {
        margin: 0.1rem 0 0 0;
      }
    }
  }

  .checkout-body-content {
    display: flex;
    flex-direction: column;
  }

  .checkout-segment-closed {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 0;

    h2 {
      margin: 0;
      padding: 0;
      display: inline-block;
    }

    span {
      margin: 0.5rem 0.25rem 0 0;
      color: ${({ theme }) => theme.palette.primary.color};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ul {
    padding: 0;
    width: 100%;
  }

  li {
    width: 100%;
  }

  .checkout-inner-info {
    display: flex;
  }

  .align-text-center {
    text-align: center;
  }
  .semibold {
    font-family: ${({ theme }) => theme.typography.mainfontFamilyMedium};
  }
  .medium-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .margin-bottom-1 {
    margin-bottom: 1rem;
  }
  .regular-waldo-font {
    font-family: ${({ theme }) => theme.typography.mainfontFamily};
    color: rgb(0 50 160);
  }
  .bold-text {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .ultra-semi-text {
    font-weight: 400;
  }
  .waldo-font {
    color: rgb(0 50 160);
  }
  .full-width {
    width: 100%;
  }
  .full-height {
    height: 100%;
  }

  .textInputCheckout,
  .selectInputCheckout {
    input[type='input'],
    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    select,
    textarea {
      width: 100%;
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};
      font-family: ${({ theme }) => theme.typography.mainFontFamily};
      font-size: 1rem;
      line-height: unset;
      outline: 2px solid transparent;
      outline-offset: 2px;
      box-sizing: border-box;
      color: ${({ theme }) => theme.palette.primary.color};
      border-bottom: 1px solid
        ${({ theme }) => theme.palette.primary.rgba('0.25')};
      &::placeholder {
        color: ${({ theme }) => theme.palette.primary.color};
        opacity: 50%;
      }
    }
  }
`;
