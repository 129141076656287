import React from 'react';
import { ButtonInputElement } from '../../../elements';
import { CheckoutUpsellTemplateProps } from '../interfaces';
import { CheckoutUpsellStyled } from '../styles';

export const CheckoutUpsellBasketTemplate = (
  props: CheckoutUpsellTemplateProps
) => {
  return (
    <CheckoutUpsellStyled className={props.classes}>
      <span className="basket-upsell-title">{props.tagline}</span>
      <div className="basket-upsell-product">
        <div className="basket-upsell-image">
          <img src={props.image} alt={props.title} />
        </div>
        <div className="basket-upsell-description">
          <p>{props.title}</p>
          <p>{props.description}</p>
        </div>
      </div>
      <div className="basket-upsell-button">
        <ButtonInputElement {...props.addPromoProductProps} />
      </div>
    </CheckoutUpsellStyled>
  );
};
