import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const ButtonInputBaseStyles = css<{ theme: StyledTheme }>`
  > button {
    cursor: pointer;

    &:disabled {
      cursor: default;
      opacity: 0.5;
      border-width: 1px;
      border-style: solid;
      text-align: center;
      color: ${({ theme }) => theme.palette.primaryBackground.color};
    }
  }
`;
