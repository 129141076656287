import React, { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownStyled } from '../styles';
import { DropdownTemplateProps, IconElement } from '../../..';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';

export const DropdownSelectTemplate = (props: DropdownTemplateProps) => {
  if (!props.display) {
    throw new UnacceptableValueException(
      'Could not load dropdown template: Missing display'
    );
  }

  const preventRegion = useRef(false);

  const visibleStyles = {
    height: `${(3 + 3 * props.slots.length).toString()}rem`,
  };

  const hiddenStyles = {
    height: '0rem',
  };

  return (
    <DropdownStyled
      data-cy="dropdown-element"
      id={props.id}
      className={props.classes}
      ref={props.display === 'mobile' ? undefined : props.triggerRef}
    >
      <div
        className="dropdown-link"
        onMouseUp={() => {
          if (!preventRegion.current) {
            props.onOpen();
          } else {
            props.onClose();
          }
        }}
        onMouseDown={() => {
          if (props.isOpen) {
            preventRegion.current = true;
          } else {
            preventRegion.current = false;
            props.onOpen();
          }
        }}
      >
        <span>{props.label}</span>
        {props.isOpen ? (
          <IconElement template="iconChevronUp" styleClasses={['iconBase']} />
        ) : (
          <IconElement template="iconChevronDown" styleClasses={['iconBase']} />
        )}
      </div>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            key={props.id}
            style={{
              position: 'relative',
              display: 'block',
              bottom: '-12.4rem',
              width: props.display === 'mobile' ? '14rem' : '24rem',
              marginTop: props.display === 'mobile' ? '-12.15rem' : '-12rem',
              right: props.display === 'mobile' ? '-2.4rem' : '-2.1rem',
              transform: 'translateX(-35%)',
              backgroundColor: '#ffffff',
              overflow: 'hidden',
              zIndex: '1',
            }}
            animate={visibleStyles}
            initial={hiddenStyles}
            exit={hiddenStyles}
            transition={{
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.2,
            }}
          >
            <div className="dropdown-content" ref={props.contentRef}>
              {props.slots.map((slot, index) => {
                return (
                  <div
                    className="dropdown-slot"
                    key={index}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      slot.onClickHandler();
                    }}
                  >
                    <img src={slot.image} alt={slot.label} />
                    <div>{slot.label}</div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </DropdownStyled>
  );
};
