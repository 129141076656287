import React from 'react';
import { IconElement } from '../../..';
import { ButtonInputStyles, ButtonInputTemplateProps } from '../';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';

export const ButtonInputIconTemplate = (props: ButtonInputTemplateProps) => {
  if (!props.icon) {
    throw new UnacceptableValueException(
      'Could not load button template: Missing icon'
    );
  }

  return (
    <ButtonInputStyles data-cy="button-input-element" className={props.classes}>
      <button
        id={props.id}
        name={props.name}
        type={props.type}
        data-clean={props['data-clean']}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {!props.loading ? (
          <IconElement {...props.icon} />
        ) : (
          <div className="loading-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </button>
    </ButtonInputStyles>
  );
};
