import { OnChangeProps } from '..';

export function useOnChangeEffect(props: OnChangeProps): [(e: any) => void] {
  const onChange = (e: any) => {
    if (props.requiresValidation && props.updateErrors) {
      props.updateErrors(false, []);
    }

    if (typeof props.setValue !== 'undefined') {
      props.setValue(e.target.value);
    }

    if (typeof e.preventDefault !== 'undefined') {
      e.preventDefault();
    }

    if (typeof props.setIsTouched !== 'undefined') {
      props.setIsTouched(true);
    }

    if (!props.bypassValidation) {
      if (props.files) {
        const {
          lastModified,
          lastModifiedDate,
          name,
          size,
          type,
          webkitRelativePath,
        } = e.target.files[0];

        props.validate(e.target.value, {
          lastModified,
          lastModifiedDate,
          name,
          size,
          type,
          webkitRelativePath,
        });
      } else {
        props.validate(e.target.value);
      }
    }

    if (typeof props.onChangeHandler !== 'undefined') {
      const detail = {
        id: props.id,
        name: props.name,
        value: e.target.value,
      };

      props.onChangeHandler({ detail });
    }
  };

  return [onChange];
}
