import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const CheckoutUpsellBasketStyles = css<{ theme: StyledTheme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 1rem;
  background-color: rgb(229, 234, 245);
  border-radius: 0.5rem;

  .basket-upsell-title {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
    color: rgb(0 50 160);
  }

  .basket-upsell-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgb(242 244 250);
    padding: 1em;
    border-radius: 0.5em;

    .basket-upsell-image {
      width: 45%;
      height: auto;
    }

    .basket-upsell-description {
      width: 55%;
    }
  }

  .basket-upsell-button {
    margin-top: 1rem;
  }
`;
