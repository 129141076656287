import { useTranslation } from 'react-i18next';
import { useOnClickEffect, useOnFocusEffect } from '../../../../hooks';
import {
  ButtonInputHandlers,
  ButtonInputProps,
  ButtonInputState,
} from '../interfaces';

export function useButtonInputEffect(
  props: ButtonInputProps
): [ButtonInputState, ButtonInputHandlers] {
  const { styleClasses, onClickHandler, id, name } = props;

  const { t } = useTranslation();

  const [isTouched, setIsTouched] = useOnFocusEffect({});

  const [onClick] = useOnClickEffect({
    id,
    name,
    setIsTouched,
    onClickHandler,
    preventDefault: true,
  });

  const classes = styleClasses.join(' ');

  const state: ButtonInputState = {
    classes,
    label: t(props.label || ''),
    'data-clean': !isTouched,
  };

  const handlers: ButtonInputHandlers = { onClick };

  return [state, handlers];
}
