import { OnClickProps, OnClickEvent } from '..';

export function useOnClickEffect(props: OnClickProps): [(e?: any) => void] {
  const onClick = (e?: any) => {
    if (props.preventDefault && e) {
      e.preventDefault();
    }

    if (props.setIsTouched) {
      props.setIsTouched(true);
    }

    if (props.validate && !props.bypassValidation && e) {
      props.validate(e.target.value);
    }

    if (typeof props.onClickHandler !== 'undefined') {
      const id = props.id;
      const name = props.name;
      const detail = { id, name };
      const event: OnClickEvent = { detail };

      props.onClickHandler(event);
    }
  };

  return [onClick];
}
