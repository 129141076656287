import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const ButtonInputDiscountStyles = css<{ theme: StyledTheme }>`
  > button {
    cursor: pointer;
    width: 100%;
    height: 3.55rem;
    margin: 0 auto;
    padding: 1.079rem 2rem 0.944rem;
    background-color: rgb(229 234 245);
    text-transform: uppercase;
    border: 1px solid ${({ theme }) => theme.palette.primary.rgba('1')};
    color: ${({ theme }) => theme.palette.primary.color};
    transition: ease-in 0.2s;
    font-family: 'Lola';
    line-height: 1.4rem;

    &:hover:enabled {
      color: ${({ theme }) => theme.palette.primaryBackground.color};
      border-color: ${({ theme }) => theme.palette.primary.color};
      background-color: ${({ theme }) => theme.palette.primary.color};
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
      border-width: 1px;
      border-style: solid;
      text-align: center;
      color: ${({ theme }) => theme.palette.primary.color};
    }
  }
`;
