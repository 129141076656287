import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const ButtonInputNewsletterStyles = css<{ theme: StyledTheme }>`
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 3.55rem;
    margin: 0 auto;
    padding: 0 1rem;
    background-color: ${({ theme }) => theme.palette.primary.color};
    text-transform: uppercase;
    border: 1px solid
      ${({ theme }) => theme.palette.primaryBackground.rgba('1')};
    border-left: none;
    color: ${({ theme }) => theme.palette.primaryBackground.color};
    transition: ease-in 0.2s;
    font-family: 'Lola';
    font-size: 1.5rem;
    line-height: 1.4rem;

    &:disabled {
      cursor: default;
      border-width: 1px;
      border-color: ${({ theme }) =>
        theme.palette.primaryBackground.rgba('0.5')};
      border-left: none;
      text-align: center;
      color: ${({ theme }) => theme.palette.primaryBackground.color};
    }

    svg {
      opacity: 1;
    }

    .loading-spinner {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;

      div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 8px;
        border: 3px solid #fff;
        border-radius: 50%;
        animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
      }

      div:nth-child(1) {
        animation-delay: -0.45s;
      }

      div:nth-child(2) {
        animation-delay: -0.3s;
      }

      div:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    @keyframes loading-spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
