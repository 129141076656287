import { TextInputValidator } from '../types';
import { TextInputProps } from '../interfaces';

export function validateTextInput(
  props: TextInputProps
): [(value: string) => Promise<void>] {
  const validate = async (value: string): Promise<void> => {
    const { rules } = props;

    let valid = true;
    const errors: string[] = [];

    if (props.validators && props.validators.length) {
      const validators = props.validators.map((validator: TextInputValidator) =>
        validator(value, rules)
      );

      const results = await Promise.all(validators);
      results.map((result) => {
        if (!result.valid) {
          valid = false;
          result.errors.map((error: string) => errors.push(error));
        }
      });
    }

    if (props.updateErrors) {
      props.updateErrors(valid, errors);
    }
  };

  return [validate];
}
