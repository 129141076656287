import React from 'react';
// import { IconElement } from '../../..';
import { ButtonInputStyles, ButtonInputTemplateProps } from '../';

export const ButtonInputDefaultTemplate = (props: ButtonInputTemplateProps) => {
  return (
    <ButtonInputStyles data-cy="button-input-element" className={props.classes}>
      <button
        id={props.id}
        name={props.name}
        type={props.type}
        data-clean={props['data-clean']}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {/* {props.iconLeft ? <IconElement {...props.iconLeft} /> : ''} */}
        {props.label}
        {/* {props.iconRight ? <IconElement {...props.iconRight} /> : ''} */}
      </button>
    </ButtonInputStyles>
  );
};
