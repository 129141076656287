import React from 'react';
import { TextInputTemplateProps } from '../interfaces';
import { TextInputStyles } from '../styles';

export const TextInputSearchTemplate = (props: TextInputTemplateProps) => {
  return (
    <TextInputStyles className={props.classes}>
      <input
        id={props.id}
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        data-clean={props['data-clean']}
        data-blurred={props['data-blurred']}
        data-valid={props['data-valid']}
        pattern={props.pattern}
        inputMode={props.inputMode}
      />
    </TextInputStyles>
  );
};
