import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const TextInputInvalidStyles = css<{ theme: StyledTheme }>`
  > input {
    border-bottom: 1px solid
      ${({ theme }) => theme.palette.invalidBackground.color};
  }

  > label {
    color: ${({ theme }) => theme.palette.waldoHighlight};
  }

  .error-container {
    position: absolute;
    bottom: -0.25rem;
    left: 1.25rem;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.waldoHighlight};

    .error-icon {
      width: 18px;
      height: 17px;
      position: absolute;
      display: block;
      left: -20px;
      color: ${({ theme }) => theme.palette.invalidBackground.color};
      font-size: 1.15rem;

      > div img {
        width: 0.95rem;
      }
    }

    .error-text {
      color: ${({ theme }) => theme.palette.invalidBackground.color};
    }

    > div:nth-child(2) {
      padding-left: 0.25rem;
    }
  }
`;
