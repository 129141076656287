import { useState } from 'react';
import { OnFocusProps } from '..';

export function useOnFocusEffect(
  props: OnFocusProps
): [boolean, (touched: boolean) => void, (e?: any) => void] {
  const [isTouched, setIsTouched] = useState(!!props.touched);

  const onFocus = (e?: any) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (props.setIsBlurred) {
      props.setIsBlurred(false);
    }

    if (props.onFocusHandler) {
      props.onFocusHandler(e);
    }

    setIsTouched(true);
  };

  return [isTouched, setIsTouched, onFocus];
}
