import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const DropdownSelectStyles = css<{ theme: StyledTheme }>`
  width: 100%;
  height: 2.25rem;
  padding: 0.25rem 0 0.5rem 0;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.primaryBackground.color};

  .dropdown-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.15rem;
    cursor: pointer;

    > div {
      margin-right: 0;
      padding-top: 0;
    }
  }

  > div:nth-child(2) {
    -webkit-box-shadow: 0px -0.5px 1px 0.5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px -0.5px 1px 0.5px rgba(0, 0, 0, 0.25);
    box-shadow: 0px -0.5px 1px 0.5px rgba(0, 0, 0, 0.25);

    .dropdown-slot {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.75rem 1rem;
      color: ${({ theme }) => theme.palette.primary.color};
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.palette.primaryButton.color};
        background-color: ${({ theme }) => theme.palette.primary.rgba('0.2')};
      }

      img {
        width: 2.5rem;
        margin-right: 1.5rem;
      }
    }
  }
`;
