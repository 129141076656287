import React from 'react';
import { ButtonInputElement } from '../../../elements';
import { CheckoutUpsellTemplateProps } from '../interfaces';
import { CheckoutUpsellStyled } from '../styles';

export const CheckoutUpsellDefaultTemplate = (
  props: CheckoutUpsellTemplateProps
) => {
  return (
    <CheckoutUpsellStyled className={props.classes}>
      <div className="checkout-info-container">
        <h3>{props.tagline}</h3>
        <div className="checkout-inner-info">
          <img className="promo-image" src={props.image} alt={props.title} />
          <div className="promo-titles">
            <div className="promo-title">{props.title}</div>
            <div className="promo-subtitle">{props.description}</div>
          </div>
        </div>
        <div className="button-container">
          <ButtonInputElement {...props.addPromoProductProps} />
        </div>
      </div>
    </CheckoutUpsellStyled>
  );
};
