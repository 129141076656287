import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const DropdownFormStyles = css<{ theme: StyledTheme }>`
  position: relative;
  display: inline-block;
  width: 100%;

  .dropdown-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    color: ${({ theme }) => theme.palette.primary.color};

    img {
      margin-right: 0.25rem;
      margin-top: 0.15rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-content-wrapper {
    position: absolute;
    display: block;
    top: 0.5rem;
    right: 0.5rem;
    width: max-content;
    overflow: hidden;
    z-index: 1;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.5);

    .dropdown-content {
      width: max-content;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};

      .dropdown-slot {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        padding: 0 1.25rem 0 1rem;
        font-size: 1rem;
        color: ${({ theme }) => theme.palette.primary.color};

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          border-bottom: none;
        }

        .dropdown-slot-selected {
          width: 1.1rem;

          img {
            max-width: 1.1rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .dropdown-children {
    width: 100vw;
    top: 4.5rem;
    left: calc(25vw);
    transform: unset;
  }
`;
