import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownElement, DropdownProps, IconElement } from '../../../elements';
import { HeaderMobileMenu } from '../types';
import {
  RouteItem,
  RouteProductLinks,
  RouteDoctorLinks,
  ExtendedRouteItem,
} from '../../../../hooks';
import { Region } from '@hiwaldo/sdk';

export const HeaderMobileMenuNavFragment = (props: {
  getStartedLink?: RouteItem;
  productLinks: RouteProductLinks;
  aboutLinks: {
    ourStory: RouteItem;
    ourBlog: RouteItem;
    howWorks: RouteItem | undefined;
    visionaries: RouteItem;
    giftCard: RouteItem;
    help: RouteItem;
  };
  doctorLinks?: RouteDoctorLinks;
  homeLink: ExtendedRouteItem;
  loginLink: RouteItem;
  visionCenterLink?: RouteItem;
  howItWorksLink?: RouteItem;
  regionDropdown: DropdownProps;
  isNavSidebarOpen: boolean;
  currentMobileMenu: HeaderMobileMenu;
  currentRegion: Region;
  setMobileMenu: (menu: HeaderMobileMenu) => void;
  openNavSiderbar: () => void;
  closeNavSiderbar: () => void;
}) => {
  return (
    <AnimatePresence>
      {props.isNavSidebarOpen && (
        <motion.div
          key="mobile-menu"
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 2000,
            height: '100vh',
          }}
          animate={{ x: 0 }}
          initial={{ x: -500 }}
          exit={{ x: -500 }}
          transition={{
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.3,
          }}
        >
          <div className="navigation-sidebar navigation-sidebar-left">
            <div className="navigation-sidebar-header">
              {props.currentMobileMenu === 'main-menu' ? (
                <div
                  className="navigation-sidebar-icon"
                  onClick={() => props.closeNavSiderbar()}
                >
                  <IconElement
                    template="iconCross"
                    styleClasses={['iconBase']}
                  />
                </div>
              ) : (
                <div
                  className="navigation-submenu-close"
                  onClick={() => props.setMobileMenu('main-menu')}
                >
                  <img src="/images/icons/close-arrow-blue.png" />
                </div>
              )}
              <div className="logo">
                {' '}
                <img
                  src={props.homeLink.image}
                  alt={props.homeLink.alt}
                  width={170}
                  onClick={() => props.homeLink.onLinkHandler()}
                />
              </div>
              <div className="region-links">
                <DropdownElement {...props.regionDropdown} />
              </div>
            </div>

            <div className="navigation-sidebar-submenu">
              <AnimatePresence>
                {props.currentMobileMenu === 'main-menu' && (
                  <motion.div
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      width: '100vw',
                      height: 'calc(100vh - 5rem)',
                      backgroundColor: 'rgba(255, 255, 255)',
                    }}
                    key="menu-default"
                    animate={{ x: 0 }}
                    initial={{ x: '-100vw' }}
                    exit={{ x: '-100vw' }}
                    transition={{
                      type: 'tween',
                      ease: 'easeInOut',
                      duration: 0.3,
                    }}
                  >
                    <div className="navigation-sidebar-links">
                      {props.currentRegion !== 'US' && (
                        <div
                          className="navigation-sidebar-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.loginLink.onLinkHandler();
                          }}
                        >
                          {props.loginLink.label}
                        </div>
                      )}
                      {props.getStartedLink && (
                        <div
                          className="navigation-sidebar-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.getStartedLink!.onLinkHandler();
                          }}
                        >
                          {props.getStartedLink.label}
                        </div>
                      )}
                      <div
                        className="navigation-sidebar-link"
                        onClick={() => props.setMobileMenu('shop-all')}
                      >
                        {props.currentRegion === 'US' ? 'SHOP ALL' : 'PRODUCTS'}
                        <img src="/images/icons/arrow-up.svg" />
                      </div>
                      {props.visionCenterLink && (
                        <div
                          className="navigation-sidebar-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.visionCenterLink?.onLinkHandler();
                          }}
                        >
                          {props.visionCenterLink.label}
                        </div>
                      )}
                      <div
                        className="navigation-sidebar-link"
                        onClick={() => props.setMobileMenu('about-us')}
                      >
                        ABOUT US
                        <img src="/images/icons/arrow-up.svg" />
                      </div>
                      {props.doctorLinks && (
                        <div
                          className="navigation-sidebar-link"
                          onClick={() => props.setMobileMenu('for-doctors')}
                        >
                          FOR DOCTORS
                          <img src="/images/icons/arrow-up.svg" />
                        </div>
                      )}
                      {props.howItWorksLink && (
                        <div
                          className="navigation-sidebar-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.howItWorksLink?.onLinkHandler();
                          }}
                        >
                          HOW IT WORKS
                        </div>
                      )}
                      <div
                        className="navigation-sidebar-link"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.aboutLinks.help.onLinkHandler();
                        }}
                        dangerouslySetInnerHTML={{
                          __html: props.aboutLinks.help.label,
                        }}
                      ></div>
                      {props.currentRegion === 'US' && (
                        <div
                          className="navigation-sidebar-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.loginLink.onLinkHandler();
                          }}
                        >
                          {props.loginLink.label}
                        </div>
                      )}
                    </div>
                  </motion.div>
                )}

                {props.currentMobileMenu !== 'main-menu' && (
                  <motion.div
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      width: '100vw',
                      height: 'calc(100vh - 15rem)',
                    }}
                    key="menu-submenu"
                    animate={{ x: 0 }}
                    initial={{ x: '100vw' }}
                    exit={{ x: '100vw' }}
                    transition={{
                      type: 'tween',
                      ease: 'easeInOut',
                      duration: 0.3,
                    }}
                  >
                    <div className="navigation-submenu">
                      {props.currentMobileMenu === 'shop-all' && (
                        <>
                          <div
                            className="navigation-submenu-links"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.productLinks.vitaminBoostLenses.onLinkHandler();
                            }}
                          >
                            <span className="link-title highlighted-link-title">
                              {props.productLinks.vitaminBoostLenses.label}
                            </span>
                            <span className="link-info">
                              {props.productLinks.vitaminBoostLenses.info}
                            </span>
                          </div>
                          <div
                            className="navigation-submenu-links"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.productLinks.hydraBoostLenses.onLinkHandler();
                            }}
                          >
                            <span className="link-title">
                              {props.productLinks.hydraBoostLenses.label}
                            </span>
                          </div>
                          {props.productLinks.starterkit && (
                            <div
                              className="navigation-submenu-links"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.productLinks.starterkit!.onLinkHandler();
                              }}
                            >
                              <span className="link-title">
                                {props.productLinks.starterkit!.label}
                              </span>
                            </div>
                          )}
                          {props.productLinks.eyeDrops && (
                            <div
                              className="navigation-submenu-links"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.productLinks.eyeDrops!.onLinkHandler();
                              }}
                            >
                              <span className="link-title">
                                {props.productLinks.eyeDrops!.label}
                              </span>
                            </div>
                          )}
                          {props.productLinks.blueLightGlasses && (
                            <div
                              className="navigation-submenu-links"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.productLinks.blueLightGlasses!.onLinkHandler();
                              }}
                            >
                              <span className="link-title">
                                {props.productLinks.blueLightGlasses.label}
                              </span>
                            </div>
                          )}
                        </>
                      )}

                      {props.currentMobileMenu === 'about-us' && (
                        <>
                          <div
                            key={props.aboutLinks.ourStory.handle}
                            className="navigation-submenu-links"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.aboutLinks.ourStory.onLinkHandler();
                            }}
                          >
                            <span
                              className="link-title"
                              dangerouslySetInnerHTML={{
                                __html: props.aboutLinks.ourStory.label!,
                              }}
                            ></span>
                          </div>

                          <div
                            key={props.aboutLinks.ourBlog.handle}
                            className="navigation-submenu-links"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.aboutLinks.ourBlog.onLinkHandler();
                            }}
                          >
                            <span
                              className="link-title"
                              dangerouslySetInnerHTML={{
                                __html: props.aboutLinks.ourBlog.label!,
                              }}
                            ></span>
                          </div>
                          <div
                            key={props.aboutLinks.visionaries.handle}
                            className="navigation-submenu-links"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.aboutLinks.visionaries.onLinkHandler();
                            }}
                          >
                            <span
                              className="link-title"
                              dangerouslySetInnerHTML={{
                                __html: props.aboutLinks.visionaries.label!,
                              }}
                            ></span>
                          </div>
                          <div
                            key={props.aboutLinks.giftCard.handle}
                            className="navigation-submenu-links"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.aboutLinks.giftCard.onLinkHandler();
                            }}
                          >
                            <span
                              className="link-title"
                              dangerouslySetInnerHTML={{
                                __html: props.aboutLinks.giftCard.label!,
                              }}
                            ></span>
                          </div>
                        </>
                      )}

                      {props.doctorLinks &&
                        props.currentMobileMenu === 'for-doctors' && (
                          <>
                            <div
                              key={props.doctorLinks.partner.handle}
                              className="navigation-submenu-links"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.doctorLinks!.partner.onLinkHandler();
                              }}
                            >
                              <span
                                className="link-title"
                                dangerouslySetInnerHTML={{
                                  __html: props.doctorLinks.partner.label!,
                                }}
                              ></span>
                            </div>
                            <div
                              key={props.doctorLinks.ambassador.handle}
                              className="navigation-submenu-links"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.doctorLinks!.ambassador.onLinkHandler();
                              }}
                            >
                              <span
                                className="link-title"
                                dangerouslySetInnerHTML={{
                                  __html: props.doctorLinks.ambassador.label!,
                                }}
                              ></span>
                            </div>
                          </>
                        )}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
