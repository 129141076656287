import { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { DropdownProps, DropdownState, DropdownHandlers } from '../../..';
import { useOnClickEffect } from '../../../../hooks';

export function useDropdownEffect(
  props: DropdownProps
): [DropdownState, DropdownHandlers] {
  const {
    id,
    name,
    styleClasses,
    onClickHandler,
    onOpenHandler,
    onCloseHandler,
  } = props;

  const classes = styleClasses.join(' ');

  const [onClick] = useOnClickEffect({
    id,
    name,
    onClickHandler,
  });

  const [onOpen] = useOnClickEffect({
    id,
    name,
    onClickHandler: onOpenHandler,
  });

  const [onClose] = useOnClickEffect({
    id,
    name,
    onClickHandler: onCloseHandler,
  });

  const contentRef = useRef(null);
  const triggerRef = useRef(null);
  useOnClickOutside(triggerRef, (event) => {
    props.isOpen && !(contentRef.current as any)?.contains(event.target)
      ? onClick()
      : undefined;
  });

  const state: DropdownState = { classes, triggerRef, contentRef };

  const handlers: DropdownHandlers = {
    onClick,
    onOpen,
    onClose,
  };

  return [state, handlers];
}
