import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { HeaderBasketProps } from '../interfaces';
import { ButtonInputElement } from '../../../elements';
import { CheckoutUpsellSegment } from '../../../segments';

export const HeaderDesktopBasketFragment = (props: {
  basket: HeaderBasketProps;
}) => {
  const { basket } = props;

  const trials = basket.basketTrial.trials || [];
  const productVariants = basket.basketProductVariant.productVariants || [];

  return (
    <AnimatePresence>
      {basket?.basketDisplay.basketOpen && (
        <div className="basket-container">
          <div
            className="basket-offset"
            onClick={() => basket.basketDisplay.hideBasket()}
          />
          <div className="basket-overlay">
            <motion.div
              key="mobile-menu"
              animate={{ x: 0 }}
              initial={{ x: 500 }}
              exit={{ x: 500 }}
              transition={{
                type: 'tween',
                ease: 'easeInOut',
                duration: 0.3,
              }}
            >
              <div className="basket-sidebar basket-left">
                <div className="basket-header">
                  <div
                    className="basket-header-close"
                    onClick={() => basket.basketDisplay.hideBasket()}
                  >
                    <img
                      src="/images/icons/blue-arrow.svg"
                      alt="Close basket"
                    />
                  </div>
                  <div className="basket-header-title">
                    <h3>Your basket</h3>
                  </div>
                </div>

                <div className="basket-body">
                  {trials.length > 0 &&
                    trials.map((trial, index) => (
                      // <div
                      //   className="basket-trial basket-item"
                      //   key={`trial-${index}`}
                      // >
                      //   <div className="basket-trial-header">
                      //     <div className="basket-trial-image">
                      //       <img src={trial.icon} alt={trial.title} />
                      //     </div>
                      //     <div className="basket-trial-title">{trial.title}</div>
                      //     {/* <div className="basket-trial-eye">
                      //         {`Right Eye (OS): ${
                      //           trial.rightEye?.details?.power || 'N/A'
                      //         }`}
                      //       </div>
                      //       <div className="basket-trial-eye">
                      //         {`Left Eye (OD): ${
                      //           trial.leftEye?.details?.power || 'N/A'
                      //         }`}
                      //       </div> */}
                      //   </div>
                      //   <div className="basket-trial-body">
                      //     <div className="basket-trial-quantity">
                      //       {trial.quantity}
                      //     </div>
                      //     <div className="basket-trial-price">
                      //       {trial.linePrice}
                      //     </div>
                      //     <div
                      //       className="basket-trial-remove"
                      //       onClick={() => trial.onRemoveHandler()}
                      //     >
                      //       <img
                      //         src="/images/icons/plus_tangerine.svg"
                      //         alt="Remove trial"
                      //       />
                      //     </div>
                      //   </div>
                      // </div>
                      <div
                        className="basket-trial-variant basket-item"
                        key={`trial-${index}`}
                      >
                        <div className="basket-trial-variant-image">
                          <img src={trial.icon} alt={trial.title} />
                        </div>
                        <div className="basket-trial-variant-body">
                          <div className="basket-trial-variant-title">
                            {trial.title}
                          </div>
                          <div className="basket-trial-eye">
                            {`Right Eye: ${
                              trial.rightEye?.details?.power || 'N/A'
                            }`}
                          </div>
                          <div className="basket-trial-eye">
                            {`Left Eye: ${
                              trial.leftEye?.details?.power || 'N/A'
                            }`}
                          </div>
                        </div>
                        <div className="basket-trial-variant-manage">
                          <div
                            className="basket-trial-variant-remove"
                            onClick={() =>
                              trial.onRemoveHandler
                                ? trial.onRemoveHandler()
                                : undefined
                            }
                          >
                            <img
                              src="/images/icons/trash.svg"
                              alt="Remove product variant"
                            />
                          </div>
                          <div className="basket-trial-variant-price">
                            {trial.linePrice}
                          </div>
                        </div>
                      </div>
                    ))}

                  {productVariants.length > 0 &&
                    productVariants.map((productVariant, index) =>
                      productVariant.lens ? (
                        <div
                          className="basket-lens-variant basket-item"
                          key={`product-variant-${index}`}
                        >
                          <div className="basket-lens-variant-image">
                            <img
                              src={productVariant.icon}
                              alt={productVariant.title}
                            />
                          </div>
                          <div className="basket-lens-variant-body">
                            <div className="basket-lens-variant-title">
                              {productVariant.title}
                            </div>
                            {/* <div className="basket-lens-variant-power">
                              {`${productVariant.eye}${
                                productVariant.eye === 'Right eye'
                                  ? ' (OS)'
                                  : productVariant.eye === 'Left eye'
                                  ? ' (OD)'
                                  : ''
                              }`}
                              : {productVariant.details.power}
                            </div> */}
                            <div className="basket-lens-variant-quantity">
                              {`${productVariant.quantity} ${
                                productVariant.quantity === 1 ? 'box' : 'boxes'
                              }`}
                            </div>
                            <div className="basket-lens-variant-lenses">
                              (
                              {productVariant.quantity *
                                (productVariant.details.packSize || 30)}{' '}
                              daily lenses)
                            </div>
                          </div>
                          <div className="basket-lens-variant-manage">
                            <div
                              className="basket-lens-variant-remove"
                              onClick={() =>
                                productVariant.onRemoveHandler
                                  ? productVariant.onRemoveHandler()
                                  : undefined
                              }
                            >
                              <img
                                src="/images/icons/trash.svg"
                                alt="Remove product variant"
                              />
                            </div>
                            <div className="basket-product-variant-price">
                              {productVariant.linePrice}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`basket-product-variant basket-item ${
                            productVariant.blg
                              ? 'product-variant-blg'
                              : productVariant.drops
                              ? 'product-variant-drops'
                              : ''
                          }`}
                          key={`product-variant-${index}`}
                        >
                          <div className="basket-product-variant-image">
                            <img
                              src={productVariant.icon}
                              alt={productVariant.title}
                            />
                          </div>
                          <div className="basket-product-variant-body">
                            <div className="basket-product-variant-title">
                              {productVariant.title}
                            </div>
                            <div className="basket-product-variant-quantity">
                              <div
                                className="basket-product-variant-decrement"
                                onClick={() =>
                                  productVariant.onDecrementHandler
                                    ? productVariant.onDecrementHandler()
                                    : undefined
                                }
                              >
                                -
                              </div>
                              <div>{productVariant.quantity}</div>
                              <div
                                className="basket-product-variant-increment"
                                onClick={() =>
                                  productVariant.onIncrementHandler
                                    ? productVariant.onIncrementHandler()
                                    : undefined
                                }
                              >
                                +
                              </div>
                            </div>
                          </div>
                          <div className="basket-product-variant-manage">
                            <div
                              className="basket-product-variant-remove"
                              onClick={() =>
                                productVariant.onRemoveHandler
                                  ? productVariant.onRemoveHandler()
                                  : undefined
                              }
                            >
                              <img
                                src="/images/icons/trash.svg"
                                alt="Remove product variant"
                              />
                            </div>
                            <div className="basket-product-variant-price">
                              {productVariant.linePrice}
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  {(basket.cartItems || 0) > 0 && (
                    <>
                      <div className="basket-total">
                        <div className="basket-total-label">Subtotal:</div>
                        <div className="basket-total-price">
                          {basket.basketTotals.totals.subtotal}
                        </div>
                      </div>
                      <div className="basket-checkout-link">
                        <ButtonInputElement {...basket.checkoutButtonProps} />
                      </div>
                    </>
                  )}

                  {basket.checkoutUpsellProps && (
                    <div className="basket-upsell">
                      <CheckoutUpsellSegment {...basket.checkoutUpsellProps} />
                    </div>
                  )}
                  <div className="basket-shopping-link">
                    <a onClick={() => basket.homeLink.onLinkHandler()}>
                      Continue Shopping
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};
