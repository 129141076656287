import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const HeaderPlainStyles = css<{ theme: StyledTheme }>`
  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 0.9rem;
    background-color: #ffffff;
    z-index: 50;

    ${responsive.desktop.small(css`
      position: sticky;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    `)}

    .logo {
      display: flex;
      justify-content: center;
      width: 33%;

      img {
        cursor: pointer;
        width: 170px;
        max-width: 170px;
        height: auto;
        z-index: 10;
      }
    }
  }
`;
