import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const ButtonInputCTAStyles = css<{ theme: StyledTheme }>`
  button {
    cursor: pointer;
    width: 90%;
    height: 4rem;
    background-color: #fa6268;
    text-transform: uppercase;
    border: 1px solid ${({ theme }) => theme.palette.primaryButton.color};
    color: ${({ theme }) => theme.palette.primaryBackground.color};
    transition: ease-in 0.2s;
    font-family: ${({ theme }) => theme.typography.offsetFontFamily};
    font-size: 1.1rem;
    justify-content: center;

    ${responsive.desktop.small(
      css`
        width: 360px;
      `
    )}

    &:hover:enabled {
      color: ${({ theme }) => theme.palette.primaryButton.color};
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.primaryButton.color};
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
      border-width: 1px;
      border-style: solid;
      text-align: center;
      color: ${({ theme }) => theme.palette.primaryBackground.color};
    }
  }
`;
