import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const TextInputBaseStyles = css<{ theme: StyledTheme }>`
  position: relative;
  > label {
    color: ${({ theme }) => theme.palette.primary.color};
    font-family: ${({ theme }) => theme.typography.mainFontFamily};
  }
  > input {
    width: 100%;
    transition: all 0.2s ease-in-out 0s;
    font-family: ${({ theme }) => theme.typography.mainFontFamily};
    color: ${({ theme }) => theme.palette.primary.color};
    border: none;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.rgba('0.3')};
    outline: none;
    margin: ${({ theme }) => theme.sizing.marginM}rem 0;
    font-size: 1.045rem;
    &::placeholder {
      color: ${({ theme }) => theme.palette.primary.color};
      opacity: 50%;
    }
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.primary.color};
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;
