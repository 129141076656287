import React from 'react';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';
import { HeaderDesktopFragment, HeaderMobileFragment } from '../fragments';
import { HeaderStyles, HeaderTemplateProps } from '../../..';

export const HeaderTemplateDefault = (props: HeaderTemplateProps) => {
  if (!props.account) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing account sidebar'
    );
  }

  if (!props.shopAll) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing shop all dropdown'
    );
  }

  if (!props.basket) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing basket sidebar'
    );
  }

  if (!props.aboutDropdown) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing about dropdown'
    );
  }

  if (!props.loginLink) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing login link'
    );
  }

  if (!props.logoutLink) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing logout link'
    );
  }

  if (!props.regionDropdown) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing region dropdown'
    );
  }

  if (!props.currentMobileMenu) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing current mobile menu'
    );
  }

  if (!props.setMobileMenu) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing set mobile menu'
    );
  }

  if (!props.productLinks) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing product links'
    );
  }

  if (!props.aboutLinks) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing about links'
    );
  }

  return (
    <HeaderStyles
      data-cy="header-partial"
      id={props.id}
      className={props.classes}
    >
      <HeaderMobileFragment
        customer={props.customer}
        account={props.account}
        basket={props.basket}
        getStartedLink={props.getStartedLink}
        getStartedProps={props.getStartedProps}
        homeLink={props.homeLink}
        loginLink={props.loginLink}
        visionCenterLink={props.visionCenterLink}
        howItWorksLink={props.howItWorksLink}
        doctorLinks={props.doctorLinks}
        aboutLinks={props.aboutLinks}
        productLinks={props.productLinks}
        regionDropdown={props.regionDropdown}
        isNavSidebarOpen={props.isNavSidebarOpen}
        isAccountSidebarOpen={props.isAccountSidebarOpen}
        currentMobileMenu={props.currentMobileMenu}
        currentRegion={props.currentRegion}
        banner={props.banner}
        setMobileMenu={props.setMobileMenu}
        openNavSiderbar={props.openNavSiderbar}
        closeNavSiderbar={props.closeNavSiderbar}
        openAccountSiderbar={props.openAccountSiderbar}
        closeAccountSiderbar={props.closeAccountSiderbar}
      />
      <HeaderDesktopFragment
        customer={props.customer}
        account={props.account}
        shopAll={props.shopAll}
        basket={props.basket}
        getStartedProps={props.getStartedProps}
        homeLink={props.homeLink}
        loginLink={props.loginLink}
        logoutLink={props.logoutLink}
        visionCenterLink={props.visionCenterLink}
        doctorsDropdown={props.doctorsDropdown}
        aboutDropdown={props.aboutDropdown}
        regionDropdown={props.regionDropdown}
        banner={props.banner}
      />
    </HeaderStyles>
  );
};
