import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownStyled } from '../styles';
import { DropdownTemplateProps } from '../../..';

export const DropdownContentTemplate = (props: DropdownTemplateProps) => {
  return (
    <DropdownStyled
      data-cy="dropdown-element"
      id={props.id}
      className={props.classes}
      ref={props.triggerRef}
    >
      <div className="dropdown-link" onClick={props.onClick}>
        {props.label}
        {props.isOpen ? (
          <img src="/images/icons/arrow-up.svg" />
        ) : (
          <img src="/images/icons/toggle-down.svg" />
        )}
      </div>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            key={props.id}
            animate={{ opacity: 1, y: -10 }}
            initial={{ opacity: 0, y: -20 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.2,
            }}
          >
            <div className="dropdown-content dropdown-children">
              {props.slots.map((slot, index) => {
                return (
                  <div
                    className="dropdown-slot"
                    key={index}
                    onClick={slot.onClickHandler}
                  >
                    {slot.content}
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </DropdownStyled>
  );
};
