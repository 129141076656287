import React, { useRef } from 'react';
import { HeaderMobileMenu } from '../types';
import { HeaderMobileMenuNavFragment } from './header-mobile-menu-nav.fragment';
import { HeaderMobileBasketFragment } from './header-mobile-basket.fragment';
import { HeaderMobileAccountFragment } from './header-mobile-account.fragment';
import {
  HeaderAccountProps,
  HeaderBannerProps,
  HeaderBasketProps,
} from '../interfaces';
import {
  ExtendedRouteItem,
  RouteDoctorLinks,
  RouteItem,
  RouteProductLinks,
} from '../../../../hooks';
import {
  ButtonInputProps,
  DropdownProps,
  ButtonInputElement,
  IconElement,
} from '../../../elements';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { Region } from '@hiwaldo/sdk';

export const HeaderMobileFragment = (props: {
  customer?: Customer;
  account: HeaderAccountProps;
  basket: HeaderBasketProps;
  getStartedProps?: ButtonInputProps;
  getStartedLink?: RouteItem;
  productLinks: RouteProductLinks;
  aboutLinks: {
    ourStory: RouteItem;
    ourBlog: RouteItem;
    howWorks: RouteItem | undefined;
    visionaries: RouteItem;
    giftCard: RouteItem;
    help: RouteItem;
  };
  doctorLinks?: RouteDoctorLinks;
  homeLink: ExtendedRouteItem;
  loginLink: RouteItem;
  visionCenterLink?: RouteItem;
  howItWorksLink?: RouteItem;
  regionDropdown: DropdownProps;
  isNavSidebarOpen: boolean;
  isAccountSidebarOpen: boolean;
  currentMobileMenu: HeaderMobileMenu;
  currentRegion: Region;
  banner?: HeaderBannerProps;
  setMobileMenu: (menu: HeaderMobileMenu) => void;
  openNavSiderbar: () => void;
  closeNavSiderbar: () => void;
  openAccountSiderbar: () => void;
  closeAccountSiderbar: () => void;
}) => {
  const preventBasket = useRef(false);
  const preventAccount = useRef(false);

  return (
    <nav className="header-mobile">
      {props.banner && (
        <div className="header-banner">
          <span
            onClick={() =>
              props.banner
                ? props.banner.onClickHandler?.onLinkHandler()
                : undefined
            }
          >
            {props.banner.line1}
            {props.banner?.line2 && (
              <>
                <br />
                {props.banner.line2}
              </>
            )}
          </span>
        </div>
      )}
      <div className="header-navbar">
        <div className="hamburger" onClick={() => props.openNavSiderbar()}>
          <IconElement template="iconHamburger" styleClasses={['iconBase']} />
        </div>

        <div className="logo">
          {' '}
          <img
            src={props.homeLink.image}
            alt={props.homeLink.alt}
            width={170}
            onClick={() => props.homeLink.onLinkHandler()}
          />
        </div>

        <div
          className={`right-side-icons ${
            props.account!.isAuthenticated ? 'waldo-authed' : ''
          }`}
        >
          {(props.basket?.cartItems || 0) > 0 && (
            <div
              className="cart-link"
              onMouseUp={() => {
                if (!preventBasket.current) {
                  props.basket!.basketDisplay.showBasket();
                }
              }}
              onMouseDown={() => {
                if (props.basket?.basketDisplay.basketOpen) {
                  preventBasket.current = true;
                } else {
                  preventBasket.current = false;
                  props.basket!.basketDisplay.showBasket();
                }
              }}
            >
              <div className="cart-item-count">
                {props.basket?.cartItems || 0}
              </div>
              <img src="/images/icons/cart-icon.svg" />
            </div>
          )}

          {props.account!.isAuthenticated && props.customer ? (
            <div
              className="account-link"
              onMouseUp={() => {
                if (!preventAccount.current) {
                  props.account!.openAccountSiderbarHandler();
                }
              }}
              onMouseDown={() => {
                if (props.account?.accountOpen) {
                  preventAccount.current = true;
                } else {
                  preventAccount.current = false;
                  props.account!.openAccountSiderbarHandler();
                }
              }}
            >
              <div>W</div>
            </div>
          ) : props.getStartedProps && (props.basket?.cartItems || 0) === 0 ? (
            <div className="get-started-link">
              <ButtonInputElement {...props.getStartedProps} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <HeaderMobileMenuNavFragment
        getStartedLink={props.getStartedLink}
        homeLink={props.homeLink}
        loginLink={props.loginLink}
        visionCenterLink={props.visionCenterLink}
        howItWorksLink={props.howItWorksLink}
        doctorLinks={props.doctorLinks}
        aboutLinks={props.aboutLinks}
        productLinks={props.productLinks}
        regionDropdown={props.regionDropdown}
        isNavSidebarOpen={props.isNavSidebarOpen}
        currentMobileMenu={props.currentMobileMenu}
        currentRegion={props.currentRegion}
        setMobileMenu={props.setMobileMenu}
        openNavSiderbar={props.openNavSiderbar}
        closeNavSiderbar={props.closeNavSiderbar}
      />

      <HeaderMobileBasketFragment basket={props.basket} />

      <HeaderMobileAccountFragment account={props.account} />
    </nav>
  );
};
