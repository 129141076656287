import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownStyled } from '../styles';
import { DropdownTemplateProps } from '../../..';

export const DropdownDefaultTemplate = (props: DropdownTemplateProps) => {
  return (
    <DropdownStyled
      data-cy="dropdown-element"
      id={props.id}
      className={props.classes}
      ref={props.triggerRef}
    >
      <div className="dropdown-link" onClick={props.onClick}>
        {props.label}
        {props.isOpen ? (
          <img src="/images/icons/arrow-up.svg" />
        ) : (
          <img src="/images/icons/toggle-down.svg" />
        )}
      </div>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            key={props.id}
            style={{
              position: 'absolute',
              display: 'block',
              top: '2rem',
              left: '50%',
              width: 'max-content',
              transform: 'translateX(-50%)',
              overflow: 'hidden',
              zIndex: '1',
            }}
            animate={{
              height: `${(1 + 3 * props.slots.length).toString()}rem`,
            }}
            initial={{ height: '0rem' }}
            exit={{ height: '0rem' }}
            transition={{
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.2,
            }}
          >
            <div className="dropdown-content">
              {props.slots.map((slot, index) => {
                return (
                  <div
                    className="dropdown-slot"
                    key={index}
                    onClick={slot.onClickHandler}
                    dangerouslySetInnerHTML={{ __html: slot.label as string }}
                  ></div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </DropdownStyled>
  );
};
