import { css } from 'styled-components';
import { responsive, StyledTheme } from '../../../../styles';

export const TextInputDiscountStyles = css<{ theme: StyledTheme }>`
  position: relative;
  > label {
    color: ${({ theme }) => theme.palette.primary.color};
    font-family: ${({ theme }) => theme.typography.mainFontFamily};
  }
  > input {
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out 0s;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};
    color: ${({ theme }) => theme.palette.primary.color};
    padding: 1.05rem 0 1.1rem 1rem;
    margin: 0;
    height: 3.55rem;
    border: 0.05rem solid ${({ theme }) => theme.palette.primary.color};
    border-radius: 0;
    outline: none;
    font-size: 1.045rem;
    &::placeholder {
      color: ${({ theme }) => theme.palette.primary.color};
      opacity: 50%;
    }
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.primary.color};
    }

    ${responsive.desktop.small(
      css`
        padding-top: 1.1rem;
        padding-bottom: 1.04rem;
      `
    )}
  }
`;
