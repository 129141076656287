import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const ButtonInputAuthStyles = css<{ theme: StyledTheme }>`
  button {
    cursor: pointer;
    width: 100%;
    height: 4rem;
    background-color: ${({ theme }) => theme.palette.primary.color};
    border: 1px solid ${({ theme }) => theme.palette.primaryBackground.color};
    color: ${({ theme }) => theme.palette.primaryBackground.color};
    transition: ease-in 0.2s;
    font-family: ${({ theme }) => theme.typography.offsetFontFamily};
    font-size: 1.1rem;

    &:visited,
    &:active {
      background-color: ${({ theme }) => theme.palette.primary.color};
      border: 1px solid ${({ theme }) => theme.palette.primaryBackground.color};
      color: ${({ theme }) => theme.palette.primaryBackground.color};
    }

    ${responsive.desktop.small(
      css`
        width: 220px;
      `
    )}

    &:hover:enabled {
      color: ${({ theme }) => theme.palette.primary.color};
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.primary.color};
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
      border-width: 1px;
      border-style: solid;
      text-align: center;
      color: ${({ theme }) => theme.palette.primaryBackground.color};
    }
  }
`;
