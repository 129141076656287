import styled from 'styled-components';
import { StyledTheme, StyledThemeCheckoutMixin } from '../../../../styles';
import { TextInputBaseStyles } from './text-input-base.styles';
import { TextInputInvalidStyles } from './text-input-invalid.styles';
import { TextInputDiscountStyles } from './text-input-discount.styles';
import { TextInputNewsletterStyles } from './text-input-newsletter.styles';
import { TextInputSearchStyles } from './text-input-search.styles';
import { TextInputAuthStyles } from './text-input-auth.styles';

export const TextInputStyles = styled.div<{ theme: StyledTheme }>`
  ${StyledThemeCheckoutMixin}

  &.textInputBase {
    ${TextInputBaseStyles}
  }

  &.textInputInvalid {
    ${TextInputInvalidStyles}
  }

  &.textInputDiscount {
    ${TextInputDiscountStyles}
  }

  &.textInputNewsletter {
    ${TextInputNewsletterStyles}
  }

  &.textInputSearch {
    ${TextInputSearchStyles}
  }

  &.textInputAuth {
    ${TextInputAuthStyles}
  }
`;
