import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ToolTip } from '../../..';
import {
  useOnBlurEffect,
  useOnChangeEffect,
  useOnFocusEffect,
} from '../../../../hooks';
import {
  validateTextInput,
  TextInputProps,
  TextInputState,
  TextInputHandlers,
} from '../';

export function useTextInputEffect(
  props: TextInputProps
): [TextInputState, TextInputHandlers] {
  const disabled = props.isDisabled || false;

  const { t } = useTranslation();

  const [validate] = validateTextInput(props);

  const [isBlurred, setIsBlurred, onBlur] = useOnBlurEffect({
    id: props.id,
    name: props.name,
    validateOnBlur: props.validateOnBlur,
    onBlurHandler: props.onBlurHandler,
    validate,
  });

  const [isTouched, setIsTouched, onFocus] = useOnFocusEffect({
    touched: props.isTouched || !!props.value,
    onFocusHandler: props.onFocusHandler,
    setIsBlurred,
  });

  const [onChange] = useOnChangeEffect({
    id: props.id,
    name: props.name,
    bypassValidation: props.bypassValidation,
    requiresValidation: props.validators && props.validators.length > 0,
    onChangeHandler: props.onChangeHandler,
    updateErrors: props.updateErrors,
    setIsTouched,
    validate,
  });

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 && props.submitOnEnter && props.onSubmitHandler) {
      props.onSubmitHandler({
        detail: { id: props.id },
      });
    }
  };

  if (props.type === 'textarea') {
    props.styleClasses.push('textInputArea');
  }

  if (props.styleClasses && !props.hideValidity && isTouched) {
    if (props.valid === true && !props.errors?.length) {
      if (props.styleClasses.indexOf('textInputValid') < 0) {
        props.styleClasses.push('textInputValid');
      }

      const invalidClassIndex = props.styleClasses.indexOf('textInputInvalid');
      if (invalidClassIndex >= 0) {
        props.styleClasses.splice(invalidClassIndex, 1);
      }
    }

    if (props.valid !== true || props.errors?.length) {
      if (props.styleClasses.indexOf('textInputInvalid') < 0) {
        props.styleClasses.push('textInputInvalid');
      }

      const validClassIndex = props.styleClasses.indexOf('textInputValid');
      if (validClassIndex >= 0) {
        props.styleClasses.splice(validClassIndex, 1);
      }
    }
  }

  const classes = props.styleClasses.join(' ');

  const tips: ToolTip[] = [];
  if (
    props.errors &&
    props.errors.length &&
    !props.hideMessages &&
    isTouched &&
    isBlurred
  ) {
    props.errors.map((error: string) => {
      tips.push({ type: 'error', message: error });
    });
  }

  const state: TextInputState = {
    classes,
    label: t(props.label || ''),
    placeholder: t(props.placeholder || ''),
    disabled,
    value: props.value,
    tips,
    valid: !!props.valid,
    'data-clean': !isTouched,
    'data-blurred': isBlurred,
    'data-valid': props.valid || false,
  };

  const handlers: TextInputHandlers = {
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  };

  useEffect(() => {
    if (
      !isTouched &&
      isBlurred &&
      props.formSubmitted &&
      props.valid === null
    ) {
      validate(props.value);
    }

    if (props.rules?.confirm && props.rules?.kin && props.value) {
      if (
        (props.rules.kin !== props.value && props.valid !== false) ||
        (props.rules.kin === props.value && props.valid !== true)
      ) {
        validate(props.value);
      }
    }
  }, [props.value, props.rules, props.formSubmitted, isTouched, isBlurred]);

  return [state, handlers];
}
