import {
  HeaderProps,
  HeaderTemplateProps,
  HeaderTemplatePlain,
  HeaderTemplateDefault,
  useHeaderEffect,
} from '../../..';

export const HeaderPartial = (props: HeaderProps) => {
  const [state] = useHeaderEffect(props);

  const templateProps: HeaderTemplateProps = {
    id: props.id,
    customer: props.customer,
    display: props.display,
    isNavSidebarOpen: props.isNavSidebarOpen,
    isAccountSidebarOpen: props.isAccountSidebarOpen,
    homeLink: props.homeLink,
    loginLink: props.loginLink,
    logoutLink: props.logoutLink,
    getStartedLink: props.getStartedLink,
    visionCenterLink: props.visionCenterLink,
    howItWorksLink: props.howItWorksLink,
    doctorsDropdown: props.doctorsDropdown,
    aboutDropdown: props.aboutDropdown,
    regionDropdown: props.regionDropdown,
    productLinks: props.productLinks,
    aboutLinks: props.aboutLinks,
    doctorLinks: props.doctorLinks,
    account: props.account,
    shopAll: props.shopAll,
    basket: props.basket,
    getStartedProps: props.getStartedProps,
    currentMobileMenu: props.currentMobileMenu,
    currentRegion: props.currentRegion,
    banner: props.banner,
    setMobileMenu: props.setMobileMenu,
    openNavSiderbar: props.openNavSiderbarHandler,
    closeNavSiderbar: props.closeNavSiderbarHandler,
    openAccountSiderbar: props.openAccountSiderbarHandler,
    closeAccountSiderbar: props.closeAccountSiderbarHandler,
    ...state,
  };

  let template;
  switch (props.template) {
    case 'headerPlain':
      template = HeaderTemplatePlain(templateProps);
      break;
    case 'headerDefault':
    default:
      template = HeaderTemplateDefault(templateProps);
      break;
  }

  return template;
};
