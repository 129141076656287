import { CheckoutUpsellProps, CheckoutUpsellState } from '../../..';

export function useCheckoutUpsellEffect(
  props: CheckoutUpsellProps
): [CheckoutUpsellState] {
  const classes = props.styleClasses.join(' ');

  const state: CheckoutUpsellState = {
    classes,
  };

  return [state];
}
