import React from 'react';
import { HeaderStyles, HeaderTemplateProps } from '../../..';

export const HeaderTemplatePlain = (props: HeaderTemplateProps) => {
  return (
    <HeaderStyles
      data-cy="header-partial"
      id={props.id}
      className={props.classes}
    >
      {props.banner && (
        <div className="header-banner">
          <span
            onClick={() =>
              props.banner
                ? props.banner.onClickHandler?.onLinkHandler()
                : undefined
            }
          >
            {props.banner.line1}
            {props.banner?.line2 && (
              <>
                <br />
                {props.banner.line2}
              </>
            )}
          </span>
        </div>
      )}
      <div className="header-container">
        <div className="logo">
          {' '}
          <img
            className="logo"
            src={props.homeLink.image}
            alt={props.homeLink.alt}
            width={170}
            onClick={() => props.homeLink.onLinkHandler()}
          />
        </div>
      </div>
    </HeaderStyles>
  );
};
