import {
  useCheckoutUpsellEffect,
  CheckoutUpsellProps,
  CheckoutUpsellTemplateProps,
  CheckoutUpsellDefaultTemplate,
  CheckoutUpsellBasketTemplate,
} from '../../..';

export const CheckoutUpsellSegment = (props: CheckoutUpsellProps) => {
  const [state] = useCheckoutUpsellEffect(props);

  const templateProps: CheckoutUpsellTemplateProps = {
    id: props.id,
    tagline: props.tagline,
    title: props.title,
    description: props.description,
    image: props.image,
    addPromoProductProps: props.addPromoProductProps,
    ...state,
  };

  let template;
  switch (props.template) {
    case 'checkoutUpsellBasket':
      template = CheckoutUpsellBasketTemplate(templateProps);
      break;
    case 'checkoutUpsellDefault':
    default:
      template = CheckoutUpsellDefaultTemplate(templateProps);
      break;
  }

  return template;
};
