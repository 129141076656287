import React from 'react';
import { TextInputTemplateProps } from '../interfaces';
import { TextInputStyles } from '../styles';
import { IconElement } from '../../icon';

export const TextInputDefaultTemplate = (props: TextInputTemplateProps) => {
  return (
    <TextInputStyles data-cy="text-input-element" className={props.classes}>
      {props.label && (
        <label htmlFor={props.id}>
          <span>{props.label}</span>{' '}
        </label>
      )}

      <input
        id={props.id}
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        pattern={props.pattern}
        inputMode={props.inputMode}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        data-clean={props['data-clean']}
        data-blurred={props['data-blurred']}
        data-valid={props['data-valid']}
      />
      {props.errors && props.errors.length > 0 && !props.hideMessages && (
        <div className="error-container">
          {!props.hideErrorIcon && (
            <div className="error-icon">
              <IconElement
                template="iconExclamationReact"
                styleClasses={['iconBase']}
              />
            </div>
          )}

          <div className="error-text">{props.errors.join(', ')}</div>
        </div>
      )}
    </TextInputStyles>
  );
};
