import { DropdownProps, DropdownTemplateProps } from '../../..';
import { useDropdownEffect } from '../effect';
import {
  DropdownDefaultTemplate,
  DropdownContentTemplate,
  DropdownFormTemplate,
  DropdownSelectTemplate,
} from '../templates';

export const DropdownElement = (props: DropdownProps) => {
  const [state, handlers] = useDropdownEffect(props);

  const templateProps: DropdownTemplateProps = {
    id: props.id,
    name: props.name,
    display: props.display,
    label: props.label,
    content: props.content,
    isOpen: props.isOpen,
    slots: props.slots,
    ...state,
    ...handlers,
  };

  let template;
  switch (props.template) {
    case 'dropdownSelect':
      template = DropdownSelectTemplate(templateProps);
      break;
    case 'dropdownForm':
      template = DropdownFormTemplate(templateProps);
      break;
    case 'dropdownContent':
      template = DropdownContentTemplate(templateProps);
      break;
    case 'dropdownDefault':
    default:
      template = DropdownDefaultTemplate(templateProps);
      break;
  }

  return template;
};
