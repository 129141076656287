import styled from 'styled-components';
import { StyledTheme } from '../../../../styles';
import { ButtonInputBaseStyles } from './button-input-base.styles';
import { ButtonInputAuthStyles } from './button-input-auth.styles';
import { ButtonInputCTAStyles } from './button-input-cta.styles';
import { ButtonInputCheckoutStyles } from './button-input-checkout.styles';
import { ButtonInputDiscountStyles } from './button-input-discount.styles';
import { ButtonInputNewsletterStyles } from './button-input-newsletter.styles';
import { ButtonInputPromoStyles } from './button-input-promo.styles';

export const ButtonInputStyles = styled.div<{ theme: StyledTheme }>`
  &.buttonInputBase {
    ${ButtonInputBaseStyles}
  }

  &.buttonInputAuth {
    ${ButtonInputAuthStyles}
  }

  &.buttonInputCTA {
    ${ButtonInputCTAStyles}
  }

  &.buttonInputCheckout {
    ${ButtonInputCheckoutStyles}
  }

  &.buttonInputDiscount {
    ${ButtonInputDiscountStyles}
  }

  &.buttonInputNewsletter {
    ${ButtonInputNewsletterStyles}
  }

  &.buttonInputPromo {
    ${ButtonInputPromoStyles}
  }
`;
