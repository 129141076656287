import {
  TextInputDefaultTemplate,
  TextInputSearchTemplate,
  TextInputProps,
  TextInputTemplateProps,
  useTextInputEffect,
} from '../';

export const TextInputElement = (props: TextInputProps) => {
  const [state, handlers] = useTextInputEffect(props);

  const templateProps: TextInputTemplateProps = {
    id: props.id,
    name: props.name,
    type: props.type,
    pattern: props.pattern,
    inputMode: props.inputMode,
    autoComplete: props.autoComplete,
    errors: props.errors,
    hideErrorIcon: props.hideErrorIcon,
    hideMessages: props.hideMessages,
    ...state,
    ...handlers,
  };

  let template;
  switch (props.template) {
    case 'textInputSearch':
      template = TextInputSearchTemplate(templateProps);
      break;
    case 'textInputDefault':
    default:
      template = TextInputDefaultTemplate(templateProps);
      break;
  }

  return template;
};
