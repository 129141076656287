import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const DropdownBaseStyles = css<{ theme: StyledTheme }>`
  position: relative;
  display: inline-block;
  width: 100%;

  .dropdown-link {
    display: flex;
    flex-direction: row;

    img {
      margin-left: 0.35rem;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primaryButton.color};
      cursor: pointer;
    }
  }

  > div:nth-child(2) {
    -webkit-box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.5);

    .dropdown-content {
      width: max-content;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};

      .dropdown-slot {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        padding: 0 1rem;
        font-size: 1rem;

        &:hover {
          color: ${({ theme }) => theme.palette.primaryButton.color};
          cursor: pointer;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .dropdown-children {
    width: 100vw;
    top: 4.5rem;
    left: calc(25vw);
    transform: unset;
  }
`;
