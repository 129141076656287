import { HeaderProps, HeaderState } from '../../..';

export function useHeaderEffect(props: HeaderProps): [HeaderState] {
  const { styleClasses } = props;

  const classes = styleClasses.join(' ');

  const state: HeaderState = { classes };

  return [state];
}
