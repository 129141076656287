import {
  ButtonInputTemplateProps,
  ButtonInputDefaultTemplate,
  ButtonInputIconTemplate,
  useButtonInputEffect,
  ButtonInputProps,
} from '../';

export const ButtonInputElement = (props: ButtonInputProps) => {
  const [state, handlers] = useButtonInputEffect(props);

  const templateProps: ButtonInputTemplateProps = {
    id: props.id,
    name: props.name,
    type: props.type,
    disabled: props.isDisabled || false,
    loading: props.isLoading || false,
    icon: props.icon,
    iconLeft: props.iconLeft,
    iconRight: props.iconRight,
    ...state,
    ...handlers,
  };

  let template;
  switch (props.template) {
    case 'buttonInputIcon':
      template = ButtonInputIconTemplate(templateProps);
      break;
    case 'buttonInputDefault':
    default:
      template = ButtonInputDefaultTemplate(templateProps);
      break;
  }

  return template;
};
