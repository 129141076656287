import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const TextInputAuthStyles = css<{ theme: StyledTheme }>`
  position: relative;
  width: 100%;

  > input {
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out 0s;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
    color: ${({ theme }) => theme.palette.primaryBackground.color};
    padding: 0;
    margin: 0;
    height: 1.75rem;
    background-color: ${({ theme }) => theme.palette.primary.color};
    border-bottom: 1px solid
      ${({ theme }) => theme.palette.primaryBackground.rgba('0.3')};
    border-radius: 0;
    outline: none;
    font-size: 1.045rem;

    &::placeholder {
      color: ${({ theme }) => theme.palette.primaryBackground.color};
      opacity: 50%;
    }

    &:hover,
    &:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      color: ${({ theme }) => theme.palette.primaryBackground.color} !important;
      background-color: ${({ theme }) =>
        theme.palette.primary.color} !important;
      border-bottom: 0.05rem solid
        ${({ theme }) => theme.palette.primaryBackground.rgba('0.3')};
      -webkit-text-fill-color: ${({ theme }) =>
        theme.palette.primaryBackground.color};
      -webkit-box-shadow: 0 0 0px 1000px
        ${({ theme }) => theme.palette.primary.color} inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .error-container {
    bottom: -1.45rem;
    font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
    left: 1.25rem;
  }
`;
