import styled from 'styled-components';
import { StyledTheme } from '../../../../styles';
import { HeaderBaseStyles } from './header-base.styles';
import { HeaderPlainStyles } from './header-plain.styles';
import { HeaderBannerStyles } from './header-banner.styles';

export const HeaderStyles = styled.div<{ theme: StyledTheme }>`
  &.headerBase {
    ${HeaderBaseStyles}
  }

  &.headerPlain {
    ${HeaderPlainStyles}
  }

  &.headerBanner {
    ${HeaderBannerStyles}
  }
`;
