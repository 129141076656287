import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const CheckoutUpsellBaseStyles = css<{ theme: StyledTheme }>`
  .checkout-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    > h3 {
      margin: 0 0 1rem 0;
      font-size: 1.5rem;
      font-weight: normal;
    }

    .checkout-inner-info {
      display: flex;
      align-items: center;
      background: rgb(242 244 250);
      padding: 1em;
      border-radius: 0.5em;

      .promo-image {
        height: 6rem;
        width: 7rem;
      }

      .promo-titles {
        .promo-title {
          font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
          color: rgb(0 50 160);
        }

        .promo-subtitle {
          font-family: ${({ theme }) => theme.typography.mainFontFamily};
          color: rgb(0 50 160);
          font-weight: 500;
        }
      }
    }
  }

  .button-container {
    margin-top: 1rem;

    ${responsive.desktop.small(css`
      button {
        height: 3.55rem;
      }
    `)}
  }
`;
