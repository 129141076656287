import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const HeaderBaseStyles = css<{ theme: StyledTheme }>`
  background-color: ${({ theme }) => theme.palette.primaryBackground.color};

  ${responsive.desktop.small(css`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 4.5rem;
    background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    z-index: 5000;
  `)}

  .cart-link {
    width: 33%;
    margin-top: 0.15rem;
    cursor: pointer;

    ${responsive.desktop.small(css`
      width: 3rem;
      margin-top: 0.15rem;
    `)}

    img {
      width: 2rem;

      ${responsive.desktop.small(css`
        width: 100%;
      `)}
    }

    .cart-item-count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0.75rem;
      right: 1.2rem;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.primary.color};
      color: ${({ theme }) => theme.palette.primaryBackground.color};
      font-size: 0.75rem;
      line-height: 1rem;
      text-align: center;

      ${responsive.desktop.small(css`
        top: 0.35rem;
        right: 3.85rem;
        width: 1.25rem;
        height: 1.25rem;
      `)}
    }
  }

  .account-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 1.25rem 1.25rem 1.5rem 1.25rem;
    background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    z-index: 300;
    direction: ltr;

    ${responsive.desktop.small(css`
      width: 27rem;
    `)}

    .account-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 1.75rem;

      .account-header-close {
        cursor: pointer;

        img {
          width: 1.25rem;
        }
      }
    }

    .account-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 55%;
      height: calc(100vh - 14rem);
      font-size: 1.25rem;
      color: ${({ theme }) => theme.palette.primary.color};
      text-transform: uppercase;

      .account-links {
        width: 100%;

        .account-link {
          margin: 1.75rem 0;
          cursor: pointer;
        }
      }
    }
  }

  .basket-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.palette.primaryBackground.color};
    z-index: 300;
    direction: ltr;
    padding: 1rem;

    ${responsive.desktop.small(css`
      width: 23rem;
    `)}

    .basket-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin-bottom: 1.75rem;

      .basket-header-close {
        position: absolute;
        top: 1.8rem;
        right: 21.5rem;
        cursor: pointer;

        ${responsive.desktop.small(css`
          right: 18.5rem;
        `)}
      }
    }

    .basket-body {
      width: 100%;
      height: 100%;
      color: ${({ theme }) => theme.palette.primary.color};

      .basket-trial-variant {
        display: flex;
        flex-direction: row;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .basket-trial-variant-image {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 35%;
          padding: 0.5rem 0.5rem 0.5rem 0;
          margin: 0px;

          img {
            max-width: 6rem;
          }
        }

        .basket-trial-variant-body {
          width: 55%;
          font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};

          > div {
            line-height: 1.25rem;
          }

          .basket-trial-variant-title {
            margin-bottom: 0.75rem;
            padding-right: 1rem;
            font-size: 1.1rem;
            font-family: ${({ theme }) =>
              theme.typography.mainFontFamilyMedium};
          }

          .basket-trial-eye {
            font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};
            font-size: 1rem;
          }

          .basket-product-variant-quantity {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 26%;
            margin-left: 0.5rem;
          }
        }

        .basket-trial-variant-manage {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;

          .basket-trial-variant-remove {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
          }
        }
      }

      .basket-lens-variant {
        display: flex;
        flex-direction: row;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .basket-lens-variant-image {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 35%;
          padding: 0.5rem 0.5rem 0.5rem 0;
          margin: 0px;

          img {
            max-width: 6rem;
          }
        }

        .basket-lens-variant-body {
          width: 55%;
          font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};

          > div {
            font-size: 1rem;
            line-height: 1.25rem;
          }

          .basket-lens-variant-title {
            margin-bottom: 0.75rem;
            padding-right: 1rem;
            font-size: 1.1rem;
            font-family: ${({ theme }) =>
              theme.typography.mainFontFamilyMedium};
          }

          .basket-product-variant-quantity {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 26%;
            margin-left: 0.5rem;
          }
        }

        .basket-lens-variant-manage {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;

          .basket-lens-variant-remove {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
          }
        }
      }

      .basket-product-variant {
        display: flex;
        flex-direction: row;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .basket-product-variant-image {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 35%;
          padding: 0.5rem 0.5rem 0.5rem 0;
          margin: 0px;

          img {
            max-width: 6rem;
          }
        }

        .basket-product-variant-body {
          width: 55%;
          font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};

          > div {
            font-size: 1rem;
            line-height: 1.25rem;
          }

          .basket-product-variant-title {
            margin-bottom: 0.75rem;
            padding-right: 1rem;
            font-size: 1.1rem;
            font-family: ${({ theme }) =>
              theme.typography.mainFontFamilyMedium};
          }

          .basket-product-variant-quantity {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 40%;
            margin-left: 0.5rem;
            font-size: 1.3rem;

            .basket-product-variant-decrement,
            .basket-product-variant-increment {
              cursor: pointer;
            }
          }
        }

        .basket-product-variant-manage {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;

          .basket-product-variant-remove {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
          }
        }
      }

      .basket-product-variant.product-variant-drops {
        .basket-product-variant-body {
          .basket-product-variant-image {
            padding: 0 0.5rem 0 0;
          }

          .basket-product-variant-title {
            margin-bottom: 0.75rem;
            padding-top: 2rem;
          }
        }
      }

      .basket-product-variant.product-variant-blg {
        .basket-product-variant-body .basket-product-variant-title {
          margin-bottom: 0.75rem;
          padding-top: 0.5rem;
        }
      }

      .basket-total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.5rem;
        padding-top: 1rem;
        font-family: SourceSansPro SemiBold;
        font-size: 1.15rem;
      }

      .basket-checkout-link {
        width: 100%;
        margin: 1.25rem 0;

        button {
          width: 100%;
          height: 4rem;

          ${responsive.desktop.small(css`
            height: 3.5rem;
          `)}
        }
      }

      .basket-shopping-link {
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  .header-mobile {
    .header-navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: flex-start;
      position: fixed;
      top: 0;
      width: 100%;
      padding: 0.75rem 0.85rem;
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};
      z-index: 50;

      .hamburger {
        display: flex;
        justify-content: flex-start;
        width: 33%;
        color: ${({ theme }) => theme.palette.primary.color};
        font-size: 2.25rem;
      }

      .logo {
        display: flex;
        justify-content: center;
        width: 33%;

        img {
          width: 170px;
          height: auto;
          z-index: 10;
        }
      }

      .right-side-icons {
        display: flex;
        justify-content: flex-end;
        width: 33%;

        &.waldo-authed .cart-link .cart-item-count {
          top: 0.75rem;
          right: 3.7rem;
        }

        .account-link > div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2.5rem;
          border: 0.1rem solid ${({ theme }) => theme.palette.primary.color};
          border-radius: 100%;
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-family: ${({ theme }) => theme.typography.mainFontFamilyMedium};
          color: ${({ theme }) => theme.palette.primary.color};
        }

        .get-started-link {
          .buttonInputCTA button {
            width: 80px;
            height: 2.5rem;
            font-size: 1rem;
          }
        }
      }
    }

    .navigation-sidebar {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};
      z-index: 100;
      overflow: hidden;

      .navigation-sidebar-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2.5rem;
        color: ${({ theme }) => theme.palette.primary.color};

        .navigation-sidebar-icon,
        .navigation-submenu-close {
          width: 33%;
          padding-left: 1.5rem;
          font-size: 1.75rem;

          img {
            height: 1.75rem;
          }
        }

        .navigation-submenu-close {
          font-size: 2rem;
        }

        .logo {
          width: 33%;
        }

        .region-links {
          display: flex;
          justify-content: flex-end;
          width: 33%;
          font-size: 1.05rem;

          .dropdownBase {
            width: unset;
            margin-top: 0.15rem;
            margin-right: 2.25rem;

            .dropdown-content {
              top: 1rem;
            }
          }
        }
      }

      .navigation-sidebar-submenu {
        height: 100%;
        background-color: ${({ theme }) =>
          theme.palette.primaryBackground.color};

        .navigation-submenu {
          width: 100%;
        }
      }

      .navigation-sidebar-links {
        display: flex;
        flex-direction: column;
        margin-top: -6rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        text-transform: uppercase;
        background-color: ${({ theme }) =>
          theme.palette.primaryBackground.color};

        .navigation-sidebar-link {
          display: flex;
          flex-direction: row;
          padding: 0.75rem 0;
          color: ${({ theme }) => theme.palette.primary.color};
          font-size: 1.15rem;

          img {
            margin-left: 0.5rem;
            transform: rotate(90deg);
          }
        }
      }

      .navigation-submenu {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: ${({ theme }) =>
          theme.palette.primaryBackground.color};

        .navigation-submenu-links {
          display: flex;
          flex-direction: column;
          padding: 0.75rem 0;
          font-size: 1.15rem;
          color: ${({ theme }) => theme.palette.primary.color};

          .link-title {
            text-transform: uppercase;
          }

          .highlighted-link-title {
            color: ${({ theme }) => theme.palette.primaryButton.color};
          }

          .link-info {
            font-style: italic;
          }
        }
      }
    }

    .basket-overlay,
    .account-overlay {
      position: fixed;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 500;
      overflow: scroll;
    }
  }

  ${responsive.desktop.small(css`
    .header-mobile {
      display: none;
    }
  `)}

  .header-desktop {
    width: 100%;

    .header-navbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      z-index: 1;
      padding: 0 1rem;

      .logo {
        display: flex;
        justify-content: center;
        width: 20%;

        img {
          cursor: pointer;
          width: 170px;
          height: 32px;
          z-index: 100;
        }
      }

      .left-side-links,
      .right-side-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
        height: 3rem;
        font-size: 1.15rem;
        color: ${({ theme }) => theme.palette.primary.color};

        > div {
          padding: 0 1.5rem 0 0;
          text-transform: uppercase;
        }
      }

      .right-side-links {
        justify-content: flex-end;

        > div {
          padding: 0 0 0 1.5rem;
        }

        .buttonInputCTA button {
          width: 140px;
          height: 2.5rem;
        }

        .account-link {
          width: 3.5rem;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            border: 0.05rem solid ${({ theme }) => theme.palette.primary.color};
            border-radius: 100%;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-family: ${({ theme }) =>
              theme.typography.mainFontFamilyMedium};
            color: ${({ theme }) => theme.palette.primary.color};
            cursor: pointer;
          }
        }

        .region-links {
          width: 3.5rem;

          .dropdown-content {
            left: 12%;
          }
        }
      }

      .header-link:hover {
        color: ${({ theme }) => theme.palette.primaryButton.color};
        cursor: pointer;
      }

      .shop-all-link .header-link {
        display: flex;
        flex-direction: row;

        img {
          margin-left: 0.35rem;
        }
      }
    }

    .account-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 100;

      .account-offset {
        width: calc(100vw - 26rem);
        min-height: 100vh;
        z-index: 200;
      }

      .account-overlay {
        position: fixed;
        top: 0;
        right: 0;
        width: 26rem;
        height: 100%;
        z-index: 200;

        > div {
          position: relative;
          overflow: auto;
          height: 100%;
          top: 0;
          right: 0;
          z-index: 200;
        }
      }
    }

    .basket-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 100;

      .basket-offset {
        width: calc(100vw - 23rem);
        min-height: 100vh;
        z-index: 200;
      }

      .basket-overlay {
        position: fixed;
        top: 0;
        right: 0;
        width: 23rem;
        height: 100vh;
        z-index: 200;
        overflow: scroll;

        > div {
          position: relative;
          overflow: auto;
          height: 100vh;
          top: 0;
          right: 0;
          z-index: 200;

          &::-webkit-scrollbar {
            width: 0rem;
          }

          &::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.palette.primaryBackground.color};
          }

          &::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.palette.primary.color};
          }
        }
      }
    }

    .shop-all-container {
      position: absolute;
      width: 100vw;
      height: 19.25rem;
      display: flex;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 2rem;
      background-color: ${({ theme }) => theme.palette.primaryBackground.color};
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

      .shop-all-menu {
        display: flex;
        background-color: ${({ theme }) =>
          theme.palette.primaryBackground.color};
        color: ${({ theme }) => theme.palette.primary.color};

        .shop-all-link {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.75rem;
          width: 16rem;
          margin: 0 1.5rem;
          text-align: center;

          .shop-all-link-image {
            height: 7rem;

            img {
              max-width: 160px;
            }
          }

          .product-header {
            font-family: ${({ theme }) =>
              theme.typography.mainFontFamilyMedium};
            font-size: 1.125rem;
            line-height: 1.75rem;
          }

          .product-info {
            font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};
            font-size: 1rem;
            line-height: 1.5rem;
          }

          &:hover {
            cursor: pointer;

            .product-header {
              color: ${({ theme }) => theme.palette.primaryButton.color};
            }
          }
        }
      }

      .shop-all-menu.shop-all-us .shop-all-link {
        width: 16rem;
        margin: 0;
      }

      .shop-all-link.starter-kit {
        img {
          padding-top: 0.85rem;
          max-width: 180px;
        }
      }

      .shop-all-link.hydration-drops {
        img {
          padding-top: 0.5rem;
        }
      }

      .shop-all-link.vitamin-boost-lenses .shop-all-link-image img {
        max-width: 115px;
      }
    }
  }

  ${responsive.mobile.large(css`
    .header-desktop {
      display: none;
    }
  `)}
`;
