import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const HeaderBannerStyles = css<{ theme: StyledTheme }>`
  background-color: ${({ theme }) => theme.palette.primaryBackground.color};

  &.headerBase {
    ${responsive.desktop.small(css`
      display: flex;
      flex-direction: row;
      align-items: center;
      position: fixed;
      width: 100%;
      height: 6.7rem;
      z-index: 100;
    `)}

    .cart-link {
      .cart-item-count {
        top: 0.75rem;

        ${responsive.desktop.small(css`
          top: 0.9rem;
        `)}
      }
    }

    .header-mobile {
      .header-navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: flex-start;
        position: sticky;
        top: 0;
        width: 100%;
        padding: 0.75rem 0.85rem;
        z-index: 1;
      }

      ${responsive.desktop.small(css`
        display: none;
      `)}
    }

    .header-desktop {
      position: absolute;
      top: 0px;
      width: 100%;

      .header-navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        z-index: 1;
        padding: 0.5rem 1rem;
      }

      ${responsive.mobile.large(css`
        display: none;
      `)}
    }
  }

  .header-banner {
    width: 100%;
    padding: 0.3rem 2.4rem 0.4rem 2.4rem;
    background-color: #da291c;
    color: ${({ theme }) => theme.palette.primaryBackground.color};
    text-align: center;

    ${responsive.desktop.small(css`
      padding: 0.3rem 0.25rem 0.4rem 0.25rem;
    `)}

    span {
      cursor: pointer;

      span.link-span {
        text-decoration: underline;
      }
    }
  }
`;
